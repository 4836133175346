@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply font-inter;
}
body {
  @apply bg-lightYellow;
}
